import styled from '@emotion/styled';
import { Link } from 'gatsby';

const Main = styled.main`
    padding: 120px 16px;
    text-align: center;

    > p {
        font-size: 3rem;
        margin-bottom: 64px;
    }
`;

const NotFound: Page = () => (
    <Main>
        <p>Sorry, page not found!</p>
        <Link to="/">Go to Homepage</Link>
    </Main>
);

NotFound.Layout = false;

export default NotFound;
